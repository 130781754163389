import request from "@/utils/request";

// 进度列表
export function listByAlarm(data: any) {
    return request({
        url: "/project/personPlan/listByAlarm",
        method: "get",
        params: data,
    });
}

// 进度详情
export function progressDetail(id: any) {
    return request({
        url: `/project/personPlan/getById/${id}`,
        method: "get",
    });
}

// 逾期子任务
export function overPlanTask(data: any) {
    return request({
        url: `/project/personPlan/getOverPlanTask`,
        method: "get",
        params: data,
    });
}

export function taskDetail(id: any) {
    return request({
        url: `/project/personPlanTask/getById/${id}`,
        method: "get",
    });
}
