
    import { Options, Vue } from "vue-property-decorator";
    import * as api from "@/api/projectplan";
    import moment from "moment";

    @Options({})
    export default class extends Vue {
        private progressList: any[] = [];

        mounted() {
            api
                .overPlanTask({
                    id: this.$route.query.id,
                })
                .then((res: any) => {
                    if (res.code === 0) {
                        this.progressList = res.data;
                    } else {
                        this.$toast.fail(res.msg);
                    }
                });
        }

        approveStatusFilter(val: any)  {
            if (val === 0) {
                return "未发起";
            } else if (val === 1) {
                return "已发起";
            } else if (val === 2) {
                return "已通过";
            } else if (val === 3) {
                return "未通过";
            }
        }

        endRealFilter(row: any) {
            if (row.approveStatus === 2) {
                if (row.endReal && row.endPlan) {
                    const s1 = moment(row.endPlan);
                    const s2 = moment(row.endReal);
                    const diff = s2.diff(s1, "days");
                    if (diff > 0) {
                        return "red";
                    } else {
                        return "green";
                    }
                }
            } else {
                if (row.endPlan) {
                    const now = moment(new Date());
                    const s1 = moment(row.endPlan);
                    const diff = now.diff(s1, "days");
                    if (diff <= 0 && diff >= -10) {
                        return "orange";
                    } else if (diff > 0) {
                        return "red";
                    }
                }
            }
            return "";
        }

        protected progressDetail(val: any) {
            this.$router.push({ path: "/app/projectPlanTaskInfo", query: { id: val.id } });
        }
    }
